import { useInView, animated } from '@react-spring/web'

import styles from './Figure.css'

export function Figure({ figure, even, layoutClassName }) {
  const { smallFigure, largeFigure, text } = figure

  const [ref, { value }] = useInView(
    () => ({
      from: { value: 0.1 },
      to: { value: 1 },
      config: {
        friction: 120,
        tension: 420,
      },
    }),
  )

  const [large, small] = even ? [80, -80] : [-80, 80]

  return (
    <section className={cx(styles.component, layoutClassName)} {...{ ref }}>
      <SmallFigureWithText
        {...{ smallFigure, text }}
        animation={{ opacity: value, x: value.to(x => small - (x * small)) }}
        layoutClassName={styles.smallFigureLayout}
      />
      <LargeFigure
        {...{ largeFigure }}
        animation={{ opacity: value, x: value.to(x => large - (x * large)) }}
      />
    </section>
  )
}

function SmallFigureWithText({ smallFigure, text, animation, layoutClassName }) {
  return (
    <animated.div
      className={cx(styles.componentSmallFigureWithText, layoutClassName)}
      style={animation}
    >
      <h2 className={styles.smallFigure}>{smallFigure}</h2>
      <p className={styles.text}>{text}</p>
    </animated.div>
  )
}

function LargeFigure({ largeFigure, animation }) {
  return (
    <animated.strong
      className={styles.componentLargeFigure}
      style={animation}
    >
      {largeFigure}
    </animated.strong>
  )
}
